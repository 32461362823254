<template>
	<div class="mobile-login-wrap">
		<template>
			<div class="title-wrap">
				<img src="../../../../assets/mobileimages/bg.png" />
				<div class="title">
					<p>S-Trial+</p>
					<p>投票登录</p>
				</div>
			</div>
			<Form ref="loginForm" class="login-form" :model="form" :rules="rules">
				<FormItem prop="username">
					<Input placeholder="用户名" v-model="form.username">
						<Icon type="md-person" slot="prefix" />
					</Input>
				</FormItem>
				<FormItem prop="password">
					<Input
						placeholder="密码"
						v-model="form.password"
						type="password"
						:maxlength="18"
					>
						<Icon type="md-lock" slot="prefix" />
					</Input>
				</FormItem>
				<div class="code-wrap">
					<FormItem prop="code">
						<Input
							placeholder="请输入验证码"
							v-model="form.code"
							:maxlength="4"
						>
							<CtmsIcon
								slot="prefix"
								:size="18"
								type="icon-validate-code"
							></CtmsIcon>
						</Input>
					</FormItem>
					<div class="code">
						<img :src="codeUrl" alt="验证码" @click="getCode" />
					</div>
				</div>
			</Form>
			<div class="login-btn">
				<Button :loading="loading" @click="handleSubmit('loginForm')"
					>登录</Button
				>
			</div>
		</template>
	</div>
</template>

<script>
import { getCode, login } from "@/api/user/user"
import { dataToSha, getParameterByName } from "@/utils/util"
import { setStorage, clearStorage, getStorage } from "@/utils/storage"
import fetch from "isomorphic-fetch"
import api from "@/api/project/project"

const { apiGet } = api

export default {
	name: "voteLogin",
	data() {
		return {
			loading: false,
			id: "",
			projectId: "",
			form: {},
			rules: {
				username: [
					{
						required: true,
						message: "用户名不能为空",
						trigger: "blur"
					}
				],
				password: [
					{
						required: true,
						message: "密码不能为空",
						trigger: "blur"
					}
				],
				code: {
					required: true,
					message: "验证码不能为空",
					trigger: "blur"
				}
			},
			codeUrl: ""
		}
	},
	created() {},
	mounted() {
		this.id = getParameterByName("id")
		this.projectId = getParameterByName("projectId")
		const token = getStorage("token")
		if (token) {
			this.getVoteResult(token)
		} else {
			this.getCode()
		}
	},
	methods: {
		// 获取验证码
		getCode() {
			this.$asyncDo(async () => {
				const res = await getCode()
				if (res) {
					this.codeUrl = res.data
				}
			})
		},
		async handleSubmit(name) {
			const valid = await this.$refs[name].validate()
			if (valid) {
				const { username, password, code } = this.form
				this.loading = true
				const res = await login({
					username,
					password: dataToSha(password),
					code
				})
				if (res) {
					this.$Message.success("登录成功")
					this.$store.commit("user/updateState", {
						token: res.data
					})
					setStorage({ token: res.data })
					// 获取个人信息
					this.$store.dispatch("user/getInfo")
					await this.getVoteResult(res.data)
				} else {
					window.scrollTo(0, 0)
					this.getCode()
				}
				this.loading = false
			}
		},
		async getVoteResult(token) {
			this.loading = true
			const res = await fetch(`/api-v1/project/${this.projectId}`, {
				headers: new Headers({
					token
				})
			}).then(r => r.json())
			if (res) {
				const code = window.parseInt(res.code)
				if (code === 0) {
					this.$router.push({
						name: "mobileVote",
						query: {
							projectName: res.data.project.name,
							voteId: this.id,
							projectId: this.projectId
						}
					})
					return false
				}
				if (code === 10001) {
					this.$message.error("请重新登录!")
					this.$store.commit("user/updateState", {
						token: "",
						name: "",
						avatar: "",
						info: {}
					})
					clearStorage()
					this.getCode()
					return false
				}
			}
			this.getCode()
			this.loading = false
			return false
		}
	}
}
</script>

<style lang="less">
@import "../../../../my-theme/color.less";
body {
	width: 100%;
	min-width: 0;
}
.mobile-login-wrap {
	height: 100%;
	background-color: #fefdf8;
	.title-wrap {
		text-align: center;
		padding-top: 70px;
		img {
			width: 215px;
		}
		.title {
			padding-top: 24px;
			font-size: 16px;
			color: #000;
		}
	}
	.login-form {
		padding: 40px 65px 60px;
		.ivu-input {
			outline: none;
			box-shadow: none;
			border-radius: 0;
			border-left: 0;
			border-right: 0;
			border-top: 0;
			font-size: 16px;
			background: none;
		}
		.ivu-input-with-prefix {
			padding-left: 40px;
		}
		.ivu-input-prefix i {
			font-size: 24px;
			color: @primary-color;
		}
		.code-wrap {
			position: relative;
			.code {
				position: absolute;
				top: 0;
				right: 0;
				img {
					height: 30px;
					width: auto;
				}
			}
		}
	}
	.login-btn {
		text-align: center;
		.ivu-btn {
			width: 145px;
			height: 35px;
			font-size: 16px;
			line-height: 22px;
			color: #fba11c;
			border-radius: 5px;
			border: 1px solid #fba11c;
			background: none;
		}
	}
	.sign-img {
		padding-top: 170px;
		text-align: center;
		img {
			width: 230px;
		}
		p {
			font-size: 16px;
			line-height: 50px;
			color: #333;
		}
	}
}
</style>
