<template>
  <div class="his-subject-info">
    <div class="header-box">
      <h2 class="title">受试者信息标记</h2>
      <Button type="primary" @click="handleSubmit" :loading="loading">提 交</Button>
    </div>
    <div >
      <Spin fix v-if="loading"></Spin>
      <Form
        :model="formData"
        ref="addConsultForm"
        label-position="top"
        :rules="rules"
      >
        <Row :gutter="16">
          <Col span="24" class="sub-title">基本信息</Col>
          <Col span="6">
            <FormItem label="姓名" prop="patientName" >
              <Input v-model="formData.patientName" clearable placeholder="请输入姓名" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="性别" prop="sex">
              <Input v-model="formData.sex" clearable placeholder="请输入性别" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="身份证号" prop="idCard">
              <Input v-model="formData.idCard" clearable placeholder="请输入身份证号" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="手机号" prop="mobilePhone">
              <Input v-model="formData.mobilePhone" clearable placeholder="请输入手机号" />
            </FormItem>
          </Col>
          <Col span="6" >
            <FormItem label="请选择病人类型" prop="sourceType">
              <Select
                v-model="formData.sourceType"
                placeholder="请选择病人类型"
                clearable
                @on-change="handleChangeSourceType"
              >
                <Option :value="1">门诊</Option>
                <Option :value="2">住院</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="科室" prop="deptName">
              <Input v-model="formData.deptName" clearable placeholder="请输入科室" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem
              label="门诊日期"
              prop="outpatientTime"
            >
              <DatePicker
                type="date"
                style="width: 100%"
                v-model="formData.outpatientTime"
                format="yyyy-MM-dd"
                placeholder="门诊日期"
                clearable
              >
              </DatePicker>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem
              label="入院日期"
              prop="admissionTime"
            >
              <DatePicker
                type="date"
                style="width: 100%"
                v-model="formData.admissionTime"
                format="yyyy-MM-dd"
                placeholder="入院日期"
                clearable
              >
              </DatePicker>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="就诊流水号" prop="registrationNumber">
              <Input v-model="formData.registrationNumber" clearable placeholder="请输入就诊流水号" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="门诊号" prop="outpatientNumber">
              <Input v-model="formData.outpatientNumber" clearable placeholder="请输入门诊号" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="住院号" prop="admissionNo">
              <Input v-model="formData.admissionNo" clearable placeholder="请输入住院号" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem
              label="出院日期"
              prop="dischargeTime"
            >
              <DatePicker
                type="date"
                style="width: 100%"
                v-model="formData.dischargeTime"
                format="yyyy-MM-dd"
                placeholder="出院日期"
                clearable
              >
              </DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="24" class="sub-title">受试者信息</Col>
          <Col span="6">
            <FormItem label="受试者标志" prop="isscientificresearch">
              <Input v-model="formData.isscientificresearch"  placeholder="请输入受试者标志" disabled clearable/>
            </FormItem>
          </Col>
          <Col span="6" >
            <FormItem label="请选择受试者状态" prop="category">
              <Select
                v-model="formData.category"
                placeholder="请选择受试者状态"
                clearable
              >
                <Option
                  v-for="item in categoryList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.label }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="12" >
            <FormItem label="请选择参与项目名称" prop="projectId">
              <el-select
                size="small"
                style="width: 100%"
                v-model="formData.projectId"
                placeholder="请选择参与项目名称/项目编号/主要研究者"
                @change="handleChangeProject"
                clearable
                filterable
                :filter-method="remoteProjectMethod"
               >
                <el-option
                  v-for="item in projectListOptions"
                  :key="item.projectId"
                  :value="item.projectId"
                  :label="item.projectName">
                  {{
                    `${item.projectName}
                    ${item.projectAcronym ? `_${item.projectAcronym}` : ''}
                    ${item.trialSitePIName ? `_${item.trialSitePIName}` : ''}`
                  }}
                </el-option>
              </el-select>
            </FormItem>
          </Col>
<!--          <Col span="6">-->
<!--            <FormItem label="项目编号">-->
<!--              <Input v-model="formData.projectAcronym" clearable placeholder="请输入项目编号"  disabled />-->
<!--            </FormItem>-->
<!--          </Col>-->
          <Col span="6" >
            <FormItem label="主要研究者">
              <Input v-model="formData.trialSitePIName" clearable placeholder="请输入主要研究者" disabled />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  </div>
</template>

<script>
import hisSubjectApi from "@/api/hisSubject/index"

const { apiGetProjectInfoList, apiSaveProjectObjectInfo, apiGetProjectObjectInfo } = hisSubjectApi
export default {
  name: "HisSubject",
  data() {
    return {
      loading: false,
      dateLoading: false,
      fullscreen: false,
      formData: {
        patientName: '', // 患者姓名
        sex: '', // 患者性别
        idCard: '', // 身份证号
        mobilePhone: '', // 手机号


        sourceType: '', // 病人类型 Int    Y  1：门诊，2：住院
        deptName: '', // 科室名称
        outpatienttime: '', // 门诊日期
        admissiontime: '', // 入院日期

        registrationNumber: '', // 就诊流水号
        outpatientNumber: '', // 门诊号
        admissionNo: '', // 住院号
        dischargetime: '', // 出院日期

        id: '', // 受试者ID
        projectId: '', // 项目ID
        isscientificresearch: '是', // 科研患者标记

        patientId: '', // 患者id ?
        trialSitePIName: '', // 主要研究者
        projectAcronym: '' // 项目编号
      },
      categoryList: [
        {
          label: '筛选',
          value: 1
        },
        {
          label: '入组',
          value: 3
        },
        {
          label: '脱落',
          value: 3
        }
      ],
      projectList: [],
      projectListOptions: [],
      dateOptions: {
        disabledDate(date) {
          // 只能选中今天及以后的日期
          const result = date && date.valueOf() < Date.now() - 86400000
          return result
        }
      }
    }
  },
  computed: {
    rules() {
      const rules = {
        patientName: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        projectId: [{ required: true, message: "参与项目名称不能为空", trigger: "blur" }],
        sex: [{ required: true, message: "患者性别不能为空", trigger: "blur" }],
        idCard: [{ required: true, message: "身份证号不能为空", trigger: "blur" }],
        sourceType: [{ required: true, message: "病人类型不能为空", trigger: "blur" }]
      }
      return rules
    }
  },
  async created() {
    // 获取项目列表
    const res = await apiGetProjectInfoList()
    this.projectList = res.data || []
    this.projectListOptions = JSON.parse(JSON.stringify(this.projectList))

    // 根据获取浏览器参数 - 获取受试者信息
    const res1 = await apiGetProjectObjectInfo(this.$route.query)
    Object.assign(this.formData, res1.data || {})
  },
  methods: {
    handleChangeProject() {
      if (this.formData.projectId) {
        const checkedObj = this.projectList.find(item => item.projectId === this.formData.projectId)
        const { trialSitePIName, projectAcronym } = checkedObj
        this.formData.trialSitePIName = trialSitePIName
        this.formData.projectAcronym = projectAcronym
      } else {
        this.formData.trialSitePIName = ''
        this.formData.projectAcronym = ''
      }
      this.projectListOptions = JSON.parse(JSON.stringify(this.projectList))
    },
    remoteProjectMethod(query) {
      const projectList = JSON.parse(JSON.stringify(this.projectList))
      // 将查询内容转为小写
      const queryStr = query.toLowerCase()
      // 进行过滤
      this.projectListOptions = projectList.filter((item) => {
        return (
          (item.projectName && item.projectName.toLowerCase().indexOf(queryStr) > -1) ||
          (item.trialSitePIName && item.trialSitePIName.toLowerCase().indexOf(queryStr) > -1) ||
          (item.projectAcronym && item.projectAcronym.toLowerCase().indexOf(queryStr) > -1)
        )
      })
    },
    handleChangeSourceType() {
      // 根据 sourceType 来动态设置 outpatientNumber 和 admissionNo 的必填规则
      if (this.formData.sourceType === 1) {
        // sourceType为1时，outpatientNumber必填
        this.rules.outpatientNumber = [{ required: true, message: "门诊号不能为空", trigger: "blur" }]
        this.rules.admissionNo = []
      } else if (this.formData.sourceType === 2) {
        // sourceType为2时，admissionNo必填
        this.rules.admissionNo = [{ required: true, message: "住院号不能为空", trigger: "blur" }]
        this.rules.outpatientNumber = []
      }
      this.$refs.addConsultForm.resetFields()
      this.$refs.addConsultForm.validateField('outpatientNumber');
      this.$refs.addConsultForm.validateField('admissionNo');
    },
    init() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    handleSubmit() {
      this.$refs.addConsultForm.validate(async (valid) => {
        if (valid) {
          // 绑定受试者和项目信息
          const res = await apiSaveProjectObjectInfo(this.formData)
          this.$Message.success("绑定受试者和项目信息绑定成功！")
          this.loading = false
        }
      })
      return true
    }
  }
}
</script>

<style lang="less">
.his-subject-info{
  padding: 30px;
  .header-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    h2{
      font-size: 20px;
      color: #333;
      font-weight: bold;
    }
  }
  .sub-title{
    font-size: 16px;
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #999;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}
</style>
